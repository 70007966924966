// jshint node: true
const appName = 'nova';
const debugLog = require('debug-log');

debugLog.enableTimestamps(false);

// const namespaces = [
//   // Core
//   `${appName}:app.core`,
//   `${appName}:boot`,
//   `${appName}:boot:app`,
//   `${appName}:coffee:menu.service`,
//   // Lazy loading
//   `ocLazyLoad.moduleLoaded`,
//   `ocLazyLoad.moduleReloaded`,
//   `ocLazyLoad.componentLoaded`,
//   `ocLazyLoad.fileLoaded`,
//   // Modules
//   `${appName}:core`,
//   `${appName}:core:config`,
//   `${appName}:core:main.controller`,
//   `${appName}:core:login.controller`,
//   `${appName}:app.addresses`,

//   `${appName}:contacts:run`,
//   `${appName}:contacts:controller`,
//   `${appName}:contacts:contacts-edit.controller`,
// ];

// debugLog.registerNamespaces(namespaces);

module.exports = (namespace, _appName) => {
  _appName = typeof _appName === 'string' ? _appName : appName;
  _appName += _appName.length > 0 ? ':' : '';
  return debugLog.getLogger(`${_appName}${namespace}`, { strictNamespaces: false });
};
