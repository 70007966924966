// jshint node: true
'use strict';

const debug = require('debug');
const util = require('util');

const LOG_LEVELS = [
  'trace',
  'debug',
  'info',
  'warn',
  'error',
  'fatal'
];

// Internal loggers registry.
const _initialLoggers = {
  'debug-log': false,
  'debug-log:fallback': false
};

let _loggers = Object.assign({}, _initialLoggers);

let _timestampsEnabled = true;

function _getTimestamp(dateObj) {
  // return (new Date()).toISOString();
  let pad = (n) => n < 10 ? '0' + n : n;
  return (dateObj.getUTCFullYear()).toString().slice(2) + '-' +
    pad(dateObj.getUTCMonth() + 1) + '-' +
    pad(dateObj.getUTCDate()) + ' ' +
    pad(dateObj.getUTCHours()) + ':' +
    pad(dateObj.getUTCMinutes()) + ':' +
    pad(dateObj.getUTCSeconds()) + '.' +
    pad(dateObj.getUTCMilliseconds());
}

function _reformatMs(_arguments) {
  // Put ms info to the end of the string.
  if (_arguments[0].slice(-2) === 'ms') {
    let msPos = _arguments[0].lastIndexOf(' +');
    let ms = _arguments[0].slice(msPos + 1);
    _arguments[0] = _arguments[0].slice(0, msPos);
    _arguments.push(ms);
  }
  return _arguments;
}

function _writeToStdOutWithTimestamp() {
  // @TODO: Depending on the log level we will want to redirect stuff.
  // let currentLogLevel = this.logLevel;
  return process.stdout.write(_getTimestamp(new Date()) +
    util.format.apply(util, arguments) + '\n');
}

function _writeToStdOut() {
  // @TODO: Depending on the log level we will want to redirect stuff.
  // let currentLogLevel = this.logLevel;
  return process.stdout.write(util.format.apply(util, arguments) + '\n');
}

let _writeToConsole = function () {
  // @TODO: Depending on the log level we will want to redirect stuff.
  // let currentLogLevel = this.logLevel;

  let _arguments = Array.prototype.slice.call(arguments);
  _arguments = _reformatMs(_arguments);

  // Select corresponding console.(trace|debug|info|warn|error) functions.
  // Fallback to console.log if not present.
  let consoleFn;
  if (this.logLevel === 'fatal') {
    consoleFn = console && (console.error || console.log);
  }
  // Use console.info() for log.debug(), because Chrome spams the developer
  // console with [Violation] messages if you enable the "Verbose" log level.
  else if (process.browser && this.logLevel === 'debug') {
    consoleFn = console && (console.info || console.log);
  } else {
    consoleFn = console && (console[this.logLevel] || console.log);
  }
  // this hackery is required for IE8/9, where  the `console.log`
  // function doesn't have 'apply'.
  return typeof console === 'object' && consoleFn &&
    Function.prototype.apply.call(consoleFn, console, _arguments);
};

let _writeToConsoleWithTimestamp = function () {
  // @TODO: Depending on the log level we will want to redirect stuff.
  // let currentLogLevel = this.logLevel;

  // Insert timestamp.
  let _arguments = Array.prototype.slice.call(arguments);
  _arguments[0] = `${_getTimestamp(new Date())} ${_arguments[0]}`;

  return _writeToConsole.apply(this, _arguments);
};

function _debug(namespace, timestampsEnabled = true) {
  let logger = debug.apply(debug, arguments);

  // Test if we're running inside the browser or on server-side.
  if (process.browser) {
    // Assign slimmer function if timestamps are disabled. Slightly improves performance.
    logger.log = timestampsEnabled ? _writeToConsoleWithTimestamp : _writeToConsole;
  } else {
    // Assign slimmer function if timestamps are disabled. Slightly improves performance.
    logger.log = timestampsEnabled ? _writeToStdOutWithTimestamp : _writeToStdOut;
  }
  return logger;
}

function _applyLevel(logger) {
  let enable = false;
  for (let level of LOG_LEVELS) {
    if (logger[level].enabled) {
      enable = true;
    }
    logger[level].enabled = enable;
    logger[level].logLevel = level;
  }
}

function createLogger(namespace, options) {
  let _options = Object.assign({ timestamps: true }, options || {});
  let newLogger = {};
  newLogger.trace = _debug(`${namespace}:TRACE`, _options.timestamps);
  newLogger.debug = _debug(`${namespace}:DEBUG`, _options.timestamps);
  newLogger.info = _debug(`${namespace}:INFO`, _options.timestamps);
  newLogger.warn = _debug(`${namespace}:WARN`, _options.timestamps);
  newLogger.error = _debug(`${namespace}:ERROR`, _options.timestamps);
  newLogger.fatal = _debug(`${namespace}:FATAL`, _options.timestamps);
  _applyLevel(newLogger);
  // Always enable errors, overrides DEBUG variable.
  newLogger.error.enabled = true;
  newLogger.fatal.enabled = true;
  return newLogger;
}

function registerNamespaces(namespaces) {
  // Create namespaces.
  _loggers = Object.assign(_loggers, _initialLoggers);
  namespaces.forEach((namespace) => _loggers[namespace] = false);
}

function getNamespaces() {
  return Object.keys(_loggers);
}

function getLogger(namespace, options) {
  let strictNamespaces = options && options.strictNamespaces || false;
  // If strict namespacing is enabled, check if namespace exists.
  // Otherwise log an error and provide a fallback logger.
  if (namespace in _loggers || !strictNamespaces) {
    // Check if logger instance has already been created.
    if (_loggers[namespace] === false || !strictNamespaces) {
      // Create new logger instance and safe in _loggers registry.
      _loggers[namespace] = createLogger(namespace, { timestamps: _timestampsEnabled });
    }
    // Return logger instance.
    return _loggers[namespace];
  }

  // If strict namespacing is enabled, log an error and provide a fallback logger.
  // Namespace was not pre-defined. Be verbose about it and provide a fallback.
  getLogger('debug-log').error(
    `Could not find requested namespace: ${namespace}, using debug-log:fallback!`);
  return getLogger('debug-log:fallback');
}

function enableTimestamps(enable) {
  _timestampsEnabled = enable;
}

module.exports = {
  enableTimestamps,
  createLogger,
  registerNamespaces,
  getNamespaces,
  getLogger
};
